.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar {
  color: black;
  text-align: center;
  justify-content: center;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: "larger";
  font-weight: "bolder";
  box-shadow: 0 4px 6px -6px black;
  transition: box-shadow 0.3s ease;
}
.navbar:hover {
  box-shadow: 0 8px 12px -12px #3e3b46;
}

.input-container {
  display: flex;
  margin: 20px;
  align-items: center;
  padding: 15px;
  border: 1px solid hwb(213 75% 12%);
  background-color: hwb(212 89% 5%);
  box-shadow: inset 20px 0 10px -10px hwb(213 75% 12%);
}

.input-container div {
  margin-right: 10px;
}

.pagination-container {
  display: flex;
  justify-content: left;
  margin-top: 20px;
  margin-left: 40px;
}

.MuiPagination-ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.MuiPaginationItem-root {
  margin: 0 5px;
  cursor: pointer;
}

.Mui-selected {
  background-color: #4caf50;
  color: white;
  border-radius: 50%;
  padding: 8px 12px;
}

.table-container {
  max-width: 100%;
  max-height: 760px;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 10px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 10px;
  box-shadow: 9px 9px 10px -10px #3e3b46;
}

.table-container::-webkit-scrollbar {
  height: 10px;
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #716d7a;
  color: #ffffff;
}

td {
  /* max-width: 200px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Times New Roman", Times, serif;
}

.large-column {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Times New Roman", Times, serif;
}

button {
  padding: 5px 10px;
  cursor: pointer;
  /* margin-right: 5px; */
  background-color: #716d7a;
  color: white;
  border: 1px;
  border-radius: 5px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.center-heading {
  margin: 0;
  text-align: center;
  padding: 20px;
  padding-top: 10px;
}

.button-container {
  display: flex;
  justify-content: right;
  margin-top: 10px;
}
.button-container button {
  background-color: hsl(210, 2%, 48%);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 5px;
}
.navbar {
  background-color: #b5b4b6;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  width: 100%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  box-shadow: 0 9px 6px -6px #3e3b46;
  transition: box-shadow 0.3s ease;
}

.navbar:hover {
  box-shadow: 0 8px 12px -12px #3e3b46;
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #7ba87b;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px #374e37;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  animation: fadeInOut 8s ease-in-out;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.tooltip-content {
  position: absolute;
  z-index: 1;
  background-color: #333;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  max-width: 200px;
  word-wrap: break-word;
  white-space: normal;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip-content::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 8px 8px 0;
  border-color: #333 transparent transparent transparent;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
